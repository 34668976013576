import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import "./header.css";

const AppHeader = () => {
  return (
    <Navbar expand="lg" className="header">
      <Container>
        <Navbar.Brand href="#home" className="header-link">
          Faiyaz Khan
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav>
            {/* <Nav.Link href="#home">Home</Nav.Link> */}
            <Nav.Link href="#about" className="header-link">
              About Me
            </Nav.Link>
            <Nav.Link href="#work" className="header-link">
              Work Experience
            </Nav.Link>
            <Nav.Link href="#contact" className="header-link">
              Contact Me
            </Nav.Link>
            <Nav.Link href="/finance" className="header-link">
              Personal Finance
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default AppHeader;
