import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import f2 from "../../assets/images/f2.jpg";

import "./about.css";

const AppAbout = () => {
  return (
    <section id="about" className="about-block">
      <Container>
        <Row>
          <Col>
            <h2 className="section-title">About Me</h2>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6}>
            <Image className="about-img" src={f2} alt="Faiyaz Khan" fluid />
          </Col>
          <Col sm={12} md={6} className="summary">
            Hey there, I'm [Your Name], your go-to guide for mastering personal
            finance. With a background in [Your Relevant Experience], I'm here
            to simplify the financial journey for you. Whether you're saving,
            investing, or planning for the future, I've got practical tips to
            help you succeed. Let's turn your financial goals into reality
            together!
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AppAbout;
