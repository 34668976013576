import Carousel from "react-bootstrap/Carousel";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import "./hero.css";

const heroData = [
  {
    id: 1,
    text: "First slide",
    image: require("../../assets/images/f2.jpg"),
    description: "Nulla vitae elit libero, a pharetra augue mollis interdum.",
    link: "https://www.google.com",
  },
  {
    id: 2,
    text: "Second slide",
    image: require("../../assets/images/f3.jpg"),
    description: "Nulla vitae elit libero, a pharetra augue mollis interdum.",
    link: "https://www.google.com",
  },
];

const AppHero = () => {
  return (
    <section id="home" className="hero-block">
      <Container>
        <Row>
          <Col md={6} sm={12}>
            <Image
              src={require("../../assets/images/f1.jpg")}
              alt="Faiyaz Khan"
              className="hero-img"
              fluid
            />
            <Carousel className="carousel">
              {heroData.map((item) => (
                <Carousel.Item key={item.id}>
                  <img
                    className="d-block w-100"
                    src={item.image}
                    alt={item.text}
                  />
                  <Carousel.Caption>
                    <h3>{item.text}</h3>
                    <p>{item.description}</p>
                  </Carousel.Caption>
                </Carousel.Item>
              ))}
            </Carousel>
          </Col>
          <Col md={6} sm={12} xs={12}>
            <h1 className="hero-title">
              Faiyaz Khan: a dynamic financial enthusiast, combines a strategic
              mindset with a personable demeanor, making him a standout figure
              in the world of personal finance.
            </h1>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AppHero;
