import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";

import AppHeader from "./components/header/header";
import AppHero from "./components/hero/hero";
import AppAbout from "./components/about/about";
import AppContact from "./components/contact/contact";
import AppWork from "./components/work/work";
import AppFooter from "./components/footer/footer";
import AppFinance from "./components/finance/finance";

function App() {
  // add youtube info
  // add work experience
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <div className="App">
              <header id="header">
                <AppHeader />
              </header>
              <main>
                <AppHero />
                <AppAbout />
                <AppWork />
                <AppContact />
              </main>
              <footer id="footer">
                <AppFooter />
              </footer>
            </div>
          }
        />
        <Route path="/finance" element={<AppFinance />} />
        {/* <Route path="/work" element={<AppWork />} /> */}
        {/* <Route path="/contact" element={<AppContact />} /> */}
        <Route path="*" element={<h1>Page Not Found</h1>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
 